$(document).on('click','a.vtou', function () {
   /*$("ul.scrols").css('height', 'auto');
   $("a.vtou").hide();*/
    $(this).parent().prev("ul.scrols").css("height","auto");

    $(this).hide();
});
$(".item-menu ul.scrols").each(function() {
    if ( $(this).find("li").length < 6 ) {
        $(this).next("p").hide();
    }
});